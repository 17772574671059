import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Avatar, Tooltip } from '@material-ui/core';
import { getCookie } from '../Helper/CookieHelper';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CalenderMini from './CalenderMini';
import TableMini from './TableMini';
import BankHolidays from '../LeaveManagementSystem/LeaveinfoTabs/BankHolidays'
// import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import LeaveCard from './LeaveCard';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}



TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
    title: {
        flexGrow: 1,
        backgroundColor: "#007681",
    },
    color: {
        backgroundColor: "#007681",
        color: "green"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },

    orange: {
        backgroundColor: 'orange'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
});

const user = JSON.parse(getCookie("bb_ts_user"));

class UserLeave extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: 0,
        }
        this.handleData = this.handleData.bind(this);
    }

    handleData(data) {
        this.child.current.dataReceive(data);
        this.leavedetails.current.dataReceive(data);
    }

    render() {
        const { classes } = this.props;
        const goback = () => {

            window.close()
        };
        const avatarClick = (e) => {
            alert("Login Information:\nLogin Provider: BB PaRaM \nUser: " + user.last_name + ', ' + user.first_name + "\nEmail: " + user.email)
        }
        const handleChange = (event, newValue) => {
            this.setState({
                value: newValue
            })
        };
        return (
            <div>

                <div className={classes.root}>

                    <AppBar position="fixed">
                        <Toolbar >
                            <IconButton onClick={goback} edge="start" color="inherit" className={clsx(classes.menuButton)}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap className={classes.title}>
                                <Box display="flex" alignItems="flex-end">
                                    Leave Management System<Box style={{ marginLeft: '5px' }} fontSize={10} >Powered by PaRaM</Box>
                                </Box>

                            </Typography>
                            <Tooltip title="Profile">
                                <Avatar style={{ marginRight: '0px' }} onClick={e => avatarClick()} className={classes.orange}>
                                    <Box fontSize={16} fontWeight="fontWeightBold"> {user.last_name.charAt(0) + user.first_name.charAt(0)}</Box>
                                </Avatar>
                            </Tooltip>

                        </Toolbar>
                    </AppBar>

                </div>
                <Box>
                    <LeaveCard id={user.id} />
                </Box>
                <AppBar position='static' color='secondary'>
                    <Tabs value={this.state.value} style={{ marginTop: '5px', marginLeft: '8px', marginRight: '8px' }} onChange={handleChange} aria-label="simple tabs example"
                        indicatorColor="primary"
                        // textColor="primary"
                        variant="fullWidth"
                        scrollButtons="auto"
                    >
                        <Tab label="Calender View" {...a11yProps(0)} />
                        <Tab label="Table View (Download available)" {...a11yProps(1)} />
                        <Tab label="Bank Holidays" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.value} index={0}>
                    <CalenderMini value={user.id} />
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    <TableMini value={user.id} userinfo={user} />
                </TabPanel>

                <TabPanel value={this.state.value} index={2}>
                    <BankHolidays newTab={true} off_loc={1} />
                </TabPanel>
                {/* </div> */}
            </div>
        )
    }
}

export default withStyles(useStyles)(UserLeave)